<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="พื้นที่ใกล้เคียง" />
		<!-- <div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="เมือง">
					<div class="card">อุปกรณ์ต่อพ่วงที่แท้จริงที่สุดของแพลตฟอร์มนี้+ธุรกิจประกอบธุรกิจ+ความหลงใหลในเมืองเดียวกัน</div>
					<div class="address">
						<van-row type="flex" justify="center" gutter="" v-for="(val, key) in addlist" :key="key">
							<van-col span="10" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab title="ขั้นตอน">
					<div class="card">อุปกรณ์ต่อพ่วงที่แท้จริงที่สุดของแพลตฟอร์มนี้+ธุรกิจประกอบธุรกิจ+ความหลงใหลในเมืองเดียวกัน</div>
					<div class="rig-box">
						<p class="rig-title">มีทรัพยากรอะไรบ้าง?</p>
						<p class="rig-content">ผู้มีชื่อเสียงทางอินเทอร์เน็ตโมเดล, พนักงานเสิร์ฟ, รุ่นอ่อนโยน, นักศึกษาวิทยาลัย ฯลฯ</p>
						<p class="rig-title">ขอบเขตการบริการ?</p>
						<p class="rig-content">เมืองเดียวกันมีกำหนดจะเป็นอากาศทั่วประเทศโปรดติดต่อข้อตกลงการแต่งตั้งฝ่ายบริการลูกค้าออนไลน์</p>
						<p class="rig-content">อุปกรณ์ต่อพ่วงที่แท้จริงที่สุดของแพลตฟอร์มนี้+ธุรกิจประกอบธุรกิจ+ความหลงใหลในเมืองเดียวกัน</p>
					</div>
				</van-tab>
			</van-tabs>
		</div> -->
		<div class="right">
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
				<van-grid :column-num="1" :gutter="0">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<div class="horizontalItem">
							<div class="imgBox">
							<van-image class="cover_img" :src="v.img_url">
								<template v-slot:loading>
								<van-loading type="circular"/>
								</template>
							</van-image>
							<span>
								<i>สูง</i>
							</span>
							</div>
							<div class="right">
							<div class="top">
								<div>
								<van-image class="homeVip" :src="homeVip"></van-image>
								<span>การรับรอง</span>
								</div>
								<div>
								<van-image class="homeVip" :src="homeVip"></van-image>
								<span>การรับรองวิดีโอ</span>
								</div>
								<div>
								{{v.xuanfei_name}}
								</div>
							</div>
							<div class="tags">
								<!-- <span class="van-tag--large van-tag--large van-tag van-tag--default">标签1</span> -->
								<!-- <span class="van-tag--large van-tag--success van-tag van-tag--default">นักเรียน</span>
								<span class="van-tag--large van-tag--danger van-tag van-tag--default">เป๋าฮื้อ</span>
								<span class="van-tag--large van-tag--warning van-tag van-tag--default">เต้นเร่าร้อน</span> -->
							</div>
							<div class="star">
								<span class="shoufei">ความนิยม &nbsp;&nbsp;&nbsp;</span>
								<van-rate
								v-model="v.star"
								:size="20"
								color="#ffd21e"
								void-icon="star"
								void-color="#eee"
								/>
							</div>
							<div class="shencai">
								ความสูง:{{ v.shengao }}cm <br> น้ำหนักตัว:{{ v.tizhong }}kg <br> หน้าอก:{{ v.xiong }}
							</div>
							<div class="yueta">รายละเอียด</div>
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [],
			isLoading: false,
			homeVip:"img/home/vip.png",
			datalist: []
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		// getAddress() {
		// 	this.$http({
		// 		method: 'get',
		// 		url: 'address_list'
		// 	}).then(res => {
		// 		this.addlist = res.data;
		// 	});
		// },
		onRefresh() {
			setTimeout(() => {
				this.$toast("รีเฟรชสำเร็จ");
				this.getxuanfeilist();
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.datalist = res.data;
			});
		}

	},
	created() {
		// this.getAddress();
		this.getxuanfeilist();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(30deg, #7d76ef, #d63f8c);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background: linear-gradient(30deg, #7d76ef, #d63f8c);
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	// height: 90px;
	// line-height: 90px;
}
::v-deep .van-col {
	text-align: center;
	font-size: 30px;
	font-weight: 600;
	margin-top: 30px;
	height: 90px;
	line-height: 90px;
	touch-action: manipulation;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
    color: inherit;
    background: transparent;
    padding: 0;
    margin: 20px 20px;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    border: 1px solid #5f26e3;
    border-radius: 8px;
    background-color: #702cfb;
    background-image: radial-gradient(75% 25% at 50% 95%, rgba(255, 255, 255, 0.2), transparent);
    color: #fafafa;
    box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(0, 0, 0, 0.1);
    transition-property: border-color, transform, background-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.55,1,0.15,1);
    will-change: transform;
    cursor: pointer;
    border-color: #b9b7dc;
    background-color: #e8e8f2;
    background-image: radial-gradient(75% 25% at 50% 95%, #fff, transparent);
    box-shadow: inset 0 0 2px 1px #fff, 0 1px 4px 1px rgba(0, 0, 0, 0.1);
    color: #45456c;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.right {
	display: flex;
	width: calc(100% - 50px);
	margin: 0 auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
	background: none;
}
::v-deep .van-image__img {
	border-radius: 20px;
}
.rig-name {
	width: 100%;
	height: 60px;
	line-height: 60px;
	margin-top: 10px;
	background-color: #f7f7f7;
	border-radius: 0 0 15px 15px;
	font-size: 15px;
	padding-left: 10px;
}
.horizontalItem {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 2.667vw 0 4vw;
}
.horizontalItem .imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-right: 2.667vw;
}
.horizontalItem .imgBox > span {
  position: absolute;
  z-index: 1;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox > span > i{
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 26px;
}
.horizontalItem .right {
  width: 60%;
}
.horizontalItem .top {
  display: flex;
}
.horizontalItem .top > div:first-child, .horizontalItem .top > div:nth-child(2) {
  background: linear-gradient(180deg,#e7caaf,#fff7ed);
  color: #a4826b;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
  margin-right: 5px;
}
.horizontalItem .top > div:last-child {
  margin-left: 10px;
  font-size: 30px;
  color: #432989;
}
.horizontalItem .tags {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
}
.horizontalItem .tags > .van-tag{
  padding: 10px;
  font-size: 20px;
  margin: 0px 5px;
  border-radius: 5px;
  line-height: 20px;
}
.horizontalItem .star {
  display: flex;
  align-items: center;
}
.horizontalItem .star .shoufei{
  font-size: 25px;
  color: rgb(102, 102, 102);
}
.horizontalItem .shencai{
  color: rgb(153, 153, 153);
  font-size: 25px;
  margin: 5px 0px;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg,#df35ad,#4f1db5);
  color: #fff;
  border-radius: 15px;
  padding: 12px 0;
  text-align: center;
  font-size: 30px;
  width: 100%;
}
.homeVip{
  width:20px;
  height:20px;
}
.cover_img{
  border-radius: 10px;
  width:240px;
  height:240px;
}
</style>
