<template>
	<div class="container page">
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="right">
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
				<van-grid :column-num="1" :gutter="0">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<div class="horizontalItem">
							<div class="imgBox">
							<van-image class="cover_img" :src="v.img_url">
								<template v-slot:loading>
								<van-loading type="circular"/>
								</template>
							</van-image>
							<span>
								<i>สูง</i>
							</span>
							</div>
							<div class="right">
							<div class="top">
								<div>
								<van-image class="homeVip" :src="homeVip"></van-image>
								<span>การรับรอง</span>
								</div>
								<div>
								<van-image class="homeVip" :src="homeVip"></van-image>
								<span>การรับรองวิดีโอ</span>
								</div>
								<div>
								{{v.xuanfei_name}}
								</div>
							</div>
							<div class="tags">
								<!-- <span class="van-tag--large van-tag--large van-tag van-tag--default">标签1</span> -->
								<span class="van-tag--large van-tag--success van-tag van-tag--default">นักเรียน</span>
								<span class="van-tag--large van-tag--danger van-tag van-tag--default">เป๋าฮื้อ</span>
								<span class="van-tag--large van-tag--warning van-tag van-tag--default">เต้นเร่าร้อน</span>
							</div>
							<div class="star">
								<span class="shoufei">ราคา&nbsp;&nbsp;&nbsp;</span>
								<van-rate
								v-model="v.star"
								:size="20"
								color="#ffd21e"
								void-icon="star"
								void-color="#eee"
								/>
							</div>
							<div class="shencai">
								ความสูง:{{ v.shengao }}cm <br> น้ำหนักตัว:{{ v.tizhong }}kg <br> ถ้วย:{{ v.xiong }}
							</div>
							<div class="yueta">รายละเอียด</div>
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '北京',
			isLoading: false,
			homeVip:"img/home/vip.png",
			datalist: [
			]
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Choose' });
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast("รีเฟรชสำเร็จ");
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.datalist = res.data;
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	}
};
</script>

<style scoped>
.right {
	display: flex;
	width: calc(100% - 50px);
	margin: 0 auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
	background: none;
}
::v-deep .van-image__img {
	border-radius: 20px;
}
.rig-name {
	width: 100%;
	height: 60px;
	line-height: 60px;
	margin-top: 10px;
	background-color: #f7f7f7;
	border-radius: 0 0 15px 15px;
	font-size: 15px;
	padding-left: 10px;
}
.horizontalItem {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 2.667vw 0 4vw;
}
.horizontalItem .imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-right: 2.667vw;
}
.horizontalItem .imgBox > span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox > span > i{
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 26px;
}
.horizontalItem .right {
  width: 60%;
}
.horizontalItem .top {
  display: flex;
}
.horizontalItem .top > div:first-child, .horizontalItem .top > div:nth-child(2) {
  background: linear-gradient(180deg,#e7caaf,#fff7ed);
  color: #a4826b;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
  margin-right: 5px;
}
.horizontalItem .top > div:last-child {
  margin-left: 10px;
  font-size: 30px;
  color: #432989;
}
.horizontalItem .tags {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
}
.horizontalItem .tags > .van-tag{
  padding: 10px;
  font-size: 20px;
  margin: 0px 5px;
  border-radius: 5px;
  line-height: 20px;
}
.horizontalItem .star {
  display: flex;
  align-items: center;
}
.horizontalItem .star .shoufei{
  font-size: 25px;
  color: rgb(102, 102, 102);
}
.horizontalItem .shencai{
  color: rgb(153, 153, 153);
  font-size: 25px;
  margin: 5px 0px;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg,#df35ad,#4f1db5);
  color: #fff;
  border-radius: 15px;
  padding: 12px 0;
  text-align: center;
  font-size: 30px;
  width: 100%;
}
.homeVip{
  width:20px;
  height:20px;
}
.cover_img{
  border-radius: 10px;
  width:240px;
  height:240px;
}
</style>
