<template>
	<div class="page">
		<van-nav-bar title="โปรไฟล์ความงาม" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<div class="feiziInfo">
				<div class="topInfo">
					<van-row type="flex" justify="space-between">
						<van-col span="16" class="left">
							<div class="name">{{ this.xuanfeidata.xuanfei_name }}</div>
							<div class="shencai">ความสูง:{{ this.xuanfeidata.shengao }}cm <br> น้ำหนักตัว:{{ this.xuanfeidata.tizhong }}kg <br> ถ้วย:{{ this.xuanfeidata.xiong }} <br> ขอบเขตการบริการ: ทั่วประเทศ</div>
							<div class="tags">
								<span class="van-tag--large van-tag--large van-tag van-tag--default">ประเทศไทย</span>
								<span class="van-tag--large van-tag--success van-tag van-tag--default">นักเรียน</span>
								<span class="van-tag--large van-tag--danger van-tag van-tag--default">เป๋าฮื้อ</span>
								<span class="van-tag--large van-tag--warning van-tag van-tag--default">เต้นเร่าร้อน</span>
							</div>
							<div class="star">
								<span class="shoufei">ราคา&nbsp;&nbsp;&nbsp;</span>
								<van-rate
									v-model="this.xuanfeidata.star"
									:size="20"
									color="#ffd21e"
									void-icon="star"
									void-color="#eee"
								/>
							</div>
						</van-col>
						<van-col span="8">
							<div class="yuyueBox" @click="yuyue">ข้อมูลติดต่อ</div>
							<div class="dianzanimg">
								<van-image class="likes" :src="likes" @click="dianzan"></van-image>
								<p class="likesNumber">{{ this.xuanfeidata.likes }}</p>
							</div>
						</van-col>
					</van-row>
				</div>
			</div>
			<van-image width="90%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<!-- <van-button round @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">预约</van-button> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
			likes:'img/home/like.png'
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast('กรุณาติดต่อฝ่ายบริการลูกค้าออนไลน์');
		},
		dianzan(){
			this.$http({
				method: 'post',
				data:{id:this.xuanfeidata.id},
				url: 'xuanfeidianzan'
			}).then(res=>{
				if(res.code === 200){
				this.getxuanfeidata();
				this.$toast(res.msg);
				}else if(res.code ===401){
				this.$toast(res.msg);
				}
			})
		},

	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
.feiziInfo {
  background: #ebedf0;
}
.feiziInfo .topInfo {
  position: relative;
}
.feiziInfo > div {
  padding: 30px 30px;
  background: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.feiziInfo .topInfo > div:first-child {
  font-weight: 600;
}
.feiziInfo .topInfo > div:first-child > span:first-child {
  color: #2c1868;
  font-size: 5vw;
}
.feiziInfo .topInfo > div:nth-child(2) {
  color: #6b22b3;
  font-size: 3vw;
  margin: 1.333vw 0;
}
.feiziInfo .topInfo > div:nth-child(3) {
  width: 50%;
}
.shoufei{
  color: rgb(153, 153, 153);
  font-size: 25px;
}
.feiziInfo .topInfo .yuyueBox {
  position: absolute;
  right: 4vw;
  bottom: 2.667vw;
  color: #fff;
  padding: 2.667vw 4vw;
  background: linear-gradient(90deg,#df35ad,#4f1db5);
  border-radius: 7vw;
}
.feiziInfo .topInfo .dianzanimg {
  position: absolute;
  top: 4vw;
  right: 7vw;
  text-align: center;
}
.likes{
	width: 90px;
	height: 90px;
}
::v-deep .van-image {
  position: relative;
  display: inline-block;
}
.likesNumber{
  color: rgb(107, 34, 179);
  margin: 0px;
}
.name{
	margin: 10px 0;
	font-size: 35px;
	color: rgb(107, 34, 179);
}
.shencai{
	margin: 10px 0;
	color: rgb(153, 153, 153);
	font-size: 25px;
	font-weight: 500;
}
.tags{
	margin: 10px 0;
}
.star{
	display: flex;
    align-items: center;
}
.van-tag--large{
	padding: 10px;
    font-size: 25px;
    border-radius: 5px;
	margin: 5px 5px;
}
.left{
	text-align: left;
}
</style>
